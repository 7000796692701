<app-sticky-header
   [title]="'Team Member Details'"
   [saveDisabled]="form.pristine || !form.valid"
   [isNew]="!form.get('id').value"
   [multiSave]="true"
   [report]="true"
   [canEdit]="canEdit"
   (showReport)="viewReport()"
   (save)="save()"
   (saveAndClose)="saveAndClose()"
   (saveAndNew)="saveAndNew()"
   (back)="backToList()"
   (delete)="delete()"
></app-sticky-header>
<mat-card>
   <mat-card-content>
      <form [formGroup]="form">
         <div class="team-member-details row">
            <mat-form-field class="col-md-4">
               <input
                  matInput
                  appAutoFocus
                  required
                  placeholder="First Name"
                  type="text"
                  formControlName="firstName"
               />
            </mat-form-field>
            <mat-form-field class="col-md-4">
               <input
                  matInput
                  required
                  placeholder="Last Name"
                  type="text"
                  formControlName="lastName"
               />
            </mat-form-field>
            <mat-form-field class="col-md-4">
               <input matInput placeholder="Title" type="text" formControlName="title" />
            </mat-form-field>
            <app-team-member-autocomplete
               class="col-md-4"
               placeholder="Manager"
               [canEdit]="canEdit"
               [idFormControl]="getControl('managerId')"
               (optionSelected)="updateManager($event)"
            ></app-team-member-autocomplete>
            <mat-form-field class="col-md-4">
               <mat-select formControlName="primaryDepartmentId" placeholder="Department">
                  <mat-option
                     [value]="department.id"
                     *ngFor="let department of departments$ | async"
                  >
                     {{ department.name }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4">
               <mat-select formControlName="status" placeholder="Status">
                  <mat-option [value]="status" *ngFor="let status of statusKeys">
                     {{ teamMemberStatus[status] }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="row">
            <mat-form-field class="col-12">
               <textarea
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  placeholder="Overview"
                  formControlName="overview"
               ></textarea>
            </mat-form-field>
         </div>
      </form>
   </mat-card-content>
</mat-card>
<mat-tab-group animationDuration="0ms" *ngIf="showTabs$ | async">
   <mat-tab label="Assigned Tasks">
      <app-assigned-tasks
         [tasks]="tasks$"
         [competencyLevels]="competencyLevels$ | async"
         [filename]="getExportFilename()"
         [canEdit]="canEdit"
         (assign)="onAssign()"
         (unassign)="onUnassign($event)"
         (exportTasks)="exportAssignments()"
         (view)="viewTask($event)"
      ></app-assigned-tasks>
   </mat-tab>
   <mat-tab *ngIf="showTrainingPlans$ | async" label="Task Ratings">
      <app-task-ratings
         [teamMember$]="teamMember$"
         [taskRatings]="tasks"
         (taskRatingsUpdated)="updateTaskRatings($event)"
      ></app-task-ratings>
   </mat-tab>
   <mat-tab *ngIf="showTrainingPlans$ | async" label="Training Plans">
      <app-training-plans
         [trainingPlans]="trainingPlans"
         [competencyLevels]="competencyLevels$ | async"
         [tasks]="tasks$ | async"
         [taskRatings]="taskRatings"
         (trainingPlansUpdated)="updateTrainingPlans($event)"
         (trainingPlanCompleted)="completeTrainingPlan($event)"
         (viewTask)="viewTask($event)"
      ></app-training-plans>
   </mat-tab>
   <!-- <mat-tab *ngIf="competencyFeatureFlag$ | async" label="Performance Evaluations">
      <app-performance-evaluation-list
         [teamMember]="teamMember$ | async"
      ></app-performance-evaluation-list>
   </mat-tab> -->
   <mat-tab label="Direct Reports">
      <app-direct-reports
         [teamMember]="teamMember$ | async"
         [departments]="departments$ | async"
      ></app-direct-reports>
   </mat-tab>
</mat-tab-group>
