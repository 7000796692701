import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   SimpleChanges,
   OnChanges,
} from '@angular/core';
import { UserComment } from '@entities/user-comment';
import * as moment from 'moment';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { AuthUser } from '@entities/auth-user';
import { froalaConfig } from '@app/shared/config/froala.config';
import { User } from '@entities/user';
import { UserRole } from '@entities/enums/user-role';
import { DialogService } from '@app/shared/services/dialog.service';
@Component({
   selector: 'app-user-comments',
   templateUrl: './user-comments.component.html',
   styleUrls: ['./user-comments.component.scss'],
})
export class UserCommentsComponent implements OnInit, OnChanges {
   @Input() comments: UserComment[] = [];
   @Input('newest') sortNewest = true;
   @Input() canEdit = true;
   @Output() commentsUpdated = new EventEmitter<UserComment[]>();
   dirty = false;
   commentText: string;
   authUser: AuthUser;
   currentUser: User;

   editorOptions = {
      ...froalaConfig,
      placeholderText: 'Add a note',
      toolbarBottom: true,
      toolbarSticky: false,
   };

   editComments: UserComment[] = [];

   constructor(private userOrgFacade: UserOrgFacade, private dialogService: DialogService) {}

   ngOnInit(): void {
      if (this.comments) {
         this.sortComments();
      }

      this.userOrgFacade.authUser$.subscribe((user) => {
         this.authUser = user;
      });
      this.userOrgFacade.currentUser$.subscribe((user) => {
         this.currentUser = user;
      });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['comments'] || changes['newest']) {
         this.sortComments();
         this.updateDirty();
      }
   }

   saveAll() {
      if (this.commentText) {
         this.addComment();
      }
      this.editComments.forEach((comment) => {
         const editedComment = this.saveEditComment(comment);
      });
   }

   addComment() {
      const comment: UserComment = {
         text: this.commentText,
         timestamp: moment().toISOString(),
         commenter: `${this.authUser.firstName} ${this.authUser.lastName}`,
         commenterId: this.authUser.uid,
      };
      this.comments.push(comment);
      this.sortComments();
      this.commentText = null;
      this.commentsUpdated.emit(this.comments);
   }

   sortComments() {
      if (this.sortNewest) {
         this.comments = this.comments.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
      } else {
         this.comments = this.comments.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
      }
   }

   canEditComment(comment: UserComment) {
      return (
         (this.canEdit && this.authUser.uid == comment.commenterId) ||
         this.currentUser?.role == UserRole.Admin
      );
   }

   showEdit(comment: UserComment) {
      return (
         this.editComments.findIndex(
            (c) => c.timestamp == comment.timestamp && c.commenterId == comment.commenterId
         ) >= 0
      );
   }

   editComment(comment: UserComment) {
      this.editComments.push({ ...comment });
      this.updateDirty();
   }

   saveEditComment(comment: UserComment) {
      this.editComments = this.editComments.filter(
         (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
      );
      this.commentsUpdated.emit(this.comments);
      this.updateDirty();
   }

   cancelEditComment(comment: UserComment) {
      const originalComment = this.editComments.find(
         (c) => c.timestamp == comment.timestamp && c.commenterId == comment.commenterId
      );
      this.editComments = this.editComments.filter(
         (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
      );
      this.comments = this.comments.filter(
         (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
      );
      this.comments.push(originalComment);
      this.sortComments();
      this.updateDirty();
   }

   commentEditor() {
      return { ...froalaConfig, toolbarBottom: true, toolbarSticky: false };
   }

   deleteComment(comment) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Comment?',
            message: 'Do you want to delete this comment?',
            confirm: 'Yes, delete',
            deny: 'No, cancel',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result == true) {
               this.comments = this.comments.filter(
                  (c) => !(c.timestamp == comment.timestamp && c.commenterId == comment.commenterId)
               );
               this.commentsUpdated.emit(this.comments);
            }
         });
   }
   updateDirty(model?: any) {
      if (model) {
         this.dirty = true;
      } else {
         this.dirty = this.editComments.length > 0;
      }
   }
}
