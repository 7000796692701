import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { OrgEntity } from '@entities/org-entity';
import { COLLECTION_NAMES } from './collection-names';
import { processEntity } from '@app/utilities/process-entity';
import { FirestoreService } from './firestore.service';
import { inject, Injectable } from '@angular/core';
import { AppAreas } from '@entities/enums/app-areas';
import { PermissionGroup } from '@entities/permission-group';
import { AccessLevel } from '@entities/enums/access-level';
import {
   or,
   QueryCompositeFilterConstraint,
   QueryConstraint,
   where,
   WhereFilterOp,
} from '@angular/fire/firestore';

export class OrgEntityService<T extends OrgEntity> {
   entities$: Observable<T[]>;

   readonly defaultOptions = { overwrite: false, orgId: null };
   private orgId$: Observable<string>;
   private _orgId: string;
   private _destroyed$ = new Subject<void>();
   private COLLECTION_NAME: string;
   private firestore: FirestoreService = inject(FirestoreService);

   constructor() {}

   init(
      orgId$: Observable<string>,
      collectionName: string,
      area?: AppAreas,
      usePermissionGroups$?: Observable<boolean>,
      permissionGroup$?: Observable<PermissionGroup>,
      assigneeFilter?: (teamMemberId: string) => Array<[string, WhereFilterOp, string]>,
      filterId$?: Observable<string>
   ) {
      this.COLLECTION_NAME = collectionName;
      this.orgId$ = orgId$;

      this.orgId$.pipe(takeUntil(this._destroyed$)).subscribe((orgId) => {
         this._orgId = orgId;
      });
      if (area) {
         this.entities$ = combineLatest([
            this.orgId$,
            usePermissionGroups$,
            permissionGroup$,
            filterId$,
         ]).pipe(
            switchMap(([orgId, usePermissionGroups, permissionGroup, filterId]) => {
               if (!orgId) {
                  return of([]);
               } else if (usePermissionGroups) {
                  const permission = permissionGroup?.accessMap[area];
                  switch (permission?.view) {
                     case AccessLevel.ALL:
                        return this.firestore.collectionData(...this.collectionRef()) as Observable<
                           T[]
                        >;
                     case AccessLevel.ASSIGNED:
                        const collection = this.firestore.collection(...this.collectionRef());
                        const filters = assigneeFilter(filterId).map((params) =>
                           this.firestore.where(...params)
                        );
                        return from(this.firestore.queryCollection(collection, ...filters)).pipe(
                           map((snap) => {
                              const entities = snap.docs.map((doc) => doc.data()) as T[];
                              return entities;
                           })
                        );
                     case AccessLevel.NONE:
                     default:
                        return of([]);
                  }
               } else {
                  return this.firestore.collectionData(...this.collectionRef()) as Observable<T[]>;
               }
            })
         );
      } else {
         this.entities$ = this.orgId$.pipe(
            switchMap((orgId) => {
               if (orgId) {
                  return this.firestore.collectionData(...this.collectionRef()) as Observable<T[]>;
               } else {
                  return of([]);
               }
            })
         );
      }
   }

   save(
      entity: T,
      options: { overwrite?: boolean; orgId?: string } = this.defaultOptions
   ): Promise<T> {
      if (entity) {
         if (entity.id) {
            const processed = processEntity(entity);
            const docRef = this.firestore.doc(...this.collectionRef(options.orgId), processed.id);
            if (options.overwrite) {
               return this.firestore.setDoc(docRef, processed).then(() => {
                  return processed;
               });
            } else {
               return this.firestore.updateDoc(docRef, processed).then(() => {
                  return processed;
               });
            }
         } else {
            const docRef = this.firestore.doc(...this.collectionRef(options.orgId));
            const newEntity = {
               ...processEntity(entity),
               id: docRef.id,
            };
            return this.firestore.setDoc(docRef, newEntity).then(() => {
               return newEntity;
            });
         }
      } else {
         return null;
      }
   }
   batchSave(entities: T[]) {
      const batch = this.firestore.writeBatch();
      const savedEntities = [];
      entities.forEach((d) => {
         const entity = processEntity(d);
         let docRef;
         if (entity.id) {
            docRef = this.firestore.doc(...this.collectionRef(), entity.id);
         } else {
            docRef = this.firestore.doc(...this.collectionRef());
            entity.id = docRef.id;
         }
         batch.set(docRef, entity);
         savedEntities.push(entity);
      });
      return batch.commit().then(() => savedEntities);
   }

   delete(entity: T) {
      if (entity.id) {
         const docRef = this.firestore.doc(...this.collectionRef(), entity.id);
         return this.firestore.deleteDoc(docRef).then(() => {
            const deletedRef = this.firestore.doc(
               COLLECTION_NAMES.ORGANIZATIONS,
               this._orgId,
               COLLECTION_NAMES.DELETED,
               this.COLLECTION_NAME,
               entity.id
            );
            return this.firestore.setDoc(deletedRef, entity);
         });
      } else {
         return Promise.resolve();
      }
   }

   destroy() {
      this._destroyed$.next();
   }

   private collectionRef(orgId?: string) {
      return [COLLECTION_NAMES.ORGANIZATIONS, orgId || this._orgId, this.COLLECTION_NAME];
   }

   // private assigneeWhereStatement(teamMemberId: string): QueryCompositeFilterConstraint {
   //    return or(
   //       where('assigneeId', '==', teamMemberId),
   //       where('assignees', 'array-contains', teamMemberId)
   //    );
   // }
}
