import {
   Component,
   OnInit,
   Input,
   SimpleChanges,
   ViewChild,
   OnChanges,
   AfterViewInit,
   OnDestroy,
   EventEmitter,
   Output,
} from '@angular/core';
import { TableColumn, RowMenuOption } from '@app/shared/interfaces/table-column.interfaces';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { FilterValues, FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { TableService } from '@app/shared/services/table.service';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FilterBarComponent } from '../filter-bar/filter-bar.component';
import { CsvService } from '@app/shared/services/csv.service';
import { isEqual } from 'lodash';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { deepCopy } from '@app/utilities/deep-copy';

export interface TableConfig {
   columns: TableColumn<any>[];
   visibleColumns$: Observable<string[]>;
   data$: Observable<any[]>;
   filter$: Observable<FilterValues>;
   initialSort: Sort;
   primaryObject?: string;
   statusField?: string;
   rowClick: (row: any) => any;
   options?: RowMenuOption[];
   departments?: Department[];
   departmentFunctions?: DepartmentFunction[];
   noHover?: boolean;
   teamMemberField?: string;
   hideExport?: boolean;
   exportFilename?: string;
   debug?: string;
   checkboxes?: boolean;
   sort$?: Observable<Sort>;
   sortChange?: (sort: Sort) => any;
   defaultColumns?: string[];
}

@Component({
   selector: 'app-table',
   templateUrl: './table.component.html',
   styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
   @Input() config: TableConfig;
   @Input() filterBar: FilterBarConfig;

   @Output() dataUpdated = new EventEmitter();
   @Output() selectedChanged = new EventEmitter();
   @Output() sortChanged = new EventEmitter();

   @ViewChild(MatTable) table: MatTable<any>;
   @ViewChild(FilterBarComponent) filterBarComponent: FilterBarComponent;

   columns: TableColumn<any>[] = [];
   visibleColumns: string[] = [];
   displayedColumns: string[];
   initialSort: Sort;
   data$: Observable<any[]>;
   filter$: Observable<FilterValues>;
   dataSource$: Observable<any[]>;
   sort$: Observable<Sort>;

   data: any[];
   filteredData: any[];
   currentSort: Sort;

   selected: any[] = [];

   destroyed$ = new Subject<void>();

   rowClick: (row: any) => any;

   private columnsChanged = false;

   constructor(
      private tableService: TableService,
      private breakpointObserver: BreakpointObserver,
      private csvService: CsvService
   ) {
      this.setConfig();
   }

   ngOnInit(): void {
      this.setDisplayedColumns();

      if (!this.config.sort$ && this.initialSort) {
         this.triggerSort(this.initialSort);
      }
      this.breakpointObserver
         .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
         .subscribe((state) => {
            if (state.breakpoints[Breakpoints.XSmall]) {
               this.setDisplayedColumns(1);
            } else if (state.breakpoints[Breakpoints.Small]) {
               this.setDisplayedColumns(3);
            } else if (state.breakpoints[Breakpoints.Medium]) {
               this.setDisplayedColumns(5);
            } else {
               this.setDisplayedColumns();
            }
         });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['visibleColumns']) {
         this.setDisplayedColumns();
      } else if (changes['config']) {
         this.setConfig();
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   setConfig() {
      if (this.config) {
         this.data$ = this.config.data$;
         this.filter$ = this.config.filter$;

         this.columns = this.config.columns;
         this.initialSort = this.config.initialSort;
         this.rowClick = this.config.rowClick;
         this.config.visibleColumns$.subscribe((columns) => {
            if (columns?.length) {
               this.visibleColumns = [
                  this.config.checkboxes ? 'checkboxes' : undefined,
                  ...columns,
               ].filter((x) => !!x);
               this.setDisplayedColumns();
            }
         });
         if (this.config.sort$) {
            this.sort$ = this.config.sort$;
         } else {
            this.sort$ = new BehaviorSubject<Sort>(null);
         }
         this.initDataSource();
      }
   }

   initDataSource() {
      this.dataSource$ = combineLatest([
         this.data$.pipe(
            distinctUntilChanged((previous, current) => isEqual(previous, current)),
            map((data) => deepCopy(data))
         ),
         this.filter$.pipe(distinctUntilChanged((previous, current) => isEqual(previous, current))),
         this.sort$.pipe(distinctUntilChanged((previous, current) => isEqual(previous, current))),
      ]).pipe(
         map(([data, filter, sort]) => {
            const filterVal = filter || {};
            if (this.config.debug === 'task-ratings') {
               const filtered = this.tableService.fullFilterAndSort(
                  data,
                  filterVal,
                  sort,
                  this.columns,
                  this.config.primaryObject,
                  this.config.statusField,
                  this.config.departments,
                  this.config.teamMemberField
               );
               return filtered;
            } else {
               return this.tableService.fullFilterAndSort(
                  data,
                  filterVal,
                  sort,
                  this.columns,
                  this.config.primaryObject,
                  this.config.statusField,
                  this.config.departments,
                  this.config.teamMemberField
               );
            }
         })
      );
      this.data$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
         this.data = data;
      });
      this.dataSource$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
         this.filteredData = data;
         this.dataUpdated.emit(data);
      });
   }

   columnsUpdated(columns: TableColumn<any>[]) {
      this.visibleColumns = columns.map((col) => col.def);
      this.setDisplayedColumns();
   }

   setDisplayedColumns(maxColumns = 0) {
      if (!this.visibleColumns.length) {
         this.visibleColumns = this.columns.filter((c) => c.visible).map((c) => c.def);
      }
      const oldDisplayedColumns = [...(this.displayedColumns || [])];
      this.displayedColumns = [
         this.config.checkboxes ? 'checkboxes' : undefined,
         ...this.visibleColumns,
      ].filter((x) => !!x);
      if (maxColumns > 0) {
         this.displayedColumns = this.displayedColumns.slice(0, maxColumns);
      }
      if (this.config.options && this.config.options.length > 0) {
         this.displayedColumns.push('options');
      }
      this.columnsChanged =
         this.columnsChanged ||
         this.displayedColumns.some((column) => !oldDisplayedColumns.includes(column)) ||
         oldDisplayedColumns.some((column) => !this.displayedColumns.includes(column));
      if (this.table && this.columnsChanged) {
         this.table.renderRows();
         this.columnsChanged = false;
      }
   }

   triggerSort(sort: Sort) {
      this.currentSort = sort;
      if (this.config.sortChange) {
         this.config.sortChange(sort);
      } else {
         (this.sort$ as BehaviorSubject<Sort>).next(sort);
      }
   }

   showOption(option: RowMenuOption, row: any) {
      if (option.condition) {
         return option.condition(row);
      } else {
         return true;
      }
   }

   showFilters() {
      if (this.filterBarComponent) {
         return this.filterBarComponent.showFilters;
      } else {
         return true;
      }
   }
   export(filterData: boolean) {
      const sortedData = filterData
         ? this.filteredData
         : this.tableService.newSort(this.data, this.currentSort, this.columns);
      const toExport = this.mapData(sortedData);
      const filename = this.config.exportFilename || 'export';
      this.csvService.export(toExport, filename);
   }

   private mapData(data: any[]) {
      if (data) {
         const visibleColumns = this.columns.filter((col) => col.visible);
         return data.map((row) => {
            const mapped = {};
            visibleColumns.forEach((col) => {
               mapped[col.label] = col.value(row, true);
            });
            return mapped;
         });
      } else {
         return [];
      }
   }

   isSelected(row: any) {
      return this.selected.includes(this.getId(row));
   }

   toggleSelected(row: any) {
      if (this.isSelected(row)) {
         this.selected = this.selected.filter((id) => id !== this.getId(row));
      } else {
         this.selected.push(this.getId(row));
      }
      this.selectedChanged.emit(this.selected);
   }

   allSelected() {
      return (
         this.selected?.length > 0 &&
         this.filteredData
            .map((row) => this.selected.includes(this.getId(row)))
            .reduce((a, b) => a && b, true)
      );
   }

   toggleSelectAll(event: MatCheckboxChange) {
      if (event.checked) {
         this.filteredData.forEach((row) => {
            if (!this.selected.includes(this.getId(row))) {
               this.selected.push(this.getId(row));
            }
         });
      } else {
         this.selected = [];
      }
      this.selectedChanged.emit(this.selected);
   }

   getId(row: any) {
      if (this.config.primaryObject) {
         return row[this.config.primaryObject].id;
      } else {
         return row.id;
      }
   }
}
