import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   OnInit,
   Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { froalaConfig } from '@app/shared/config/froala.config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
   selector: 'app-text-editor',
   templateUrl: './text-editor.component.html',
   styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit, OnChanges, OnDestroy {
   @Input() editorConfig = froalaConfig;
   @Input() formCtrl: UntypedFormControl;
   @Input() label: string;
   @Input() value: string;
   @Input() alwaysShowLabel = false;
   @Input() canEdit = false;
   @Output() valueChange = new EventEmitter();

   valueCopy: string;
   editor: any;

   private destroyed$ = new Subject<void>();

   constructor() {}

   ngOnInit(): void {
      if (!this.formCtrl) {
         this.formCtrl = new UntypedFormControl();
         if (this.value) {
            this.formCtrl.setValue(this.value);
         }
      }
      this.formCtrl.valueChanges
         .pipe(takeUntil(this.destroyed$))
         .subscribe((value) => this.valueChange.emit(value));
   }

   ngOnChanges() {
      if (this.value && this.valueCopy !== this.value) {
         this.valueCopy = this.value;
         this.formCtrl?.setValue(this.value);
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   initFroala(controls) {
      controls.initialize();
      this.editor = controls.getEditor();
   }

   focusEditor() {
      this.editor.events.focus();
   }
}
