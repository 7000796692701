<form [formGroup]="form">
   <mat-card>
      <mat-card-content class="eval-items">
         <div class="perf-eval-item">
            <mat-form-field>
               <input
                  matInput
                  placeholder="Competency"
                  [formControl]="getControl('competency.name')"
                  type="text"
               />
            </mat-form-field>
            <mat-form-field>
               <input
                  matInput
                  placeholder="Department"
                  [formControl]="getControl('competency.departmentName')"
                  type="text"
               />
            </mat-form-field>
            <mat-form-field>
               <mat-label>Score</mat-label>
               <mat-select formControlName="score">
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="2">2</mat-option>
                  <mat-option [value]="3">3</mat-option>
                  <mat-option [value]="4">4</mat-option>
                  <mat-option [value]="5">5</mat-option>
               </mat-select>
            </mat-form-field>
            <ng-container *ngIf="showWeights">
               <mat-form-field>
                  <mat-label>Weight</mat-label>
                  <mat-select formControlName="weight">
                     <mat-option [value]="1">1</mat-option>
                     <mat-option [value]="2">2</mat-option>
                     <mat-option [value]="3">3</mat-option>
                     <mat-option [value]="4">4</mat-option>
                     <mat-option [value]="5">5</mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field>
                  <input matInput placeholder="Weighted Score" formControlName="weightedScore" />
               </mat-form-field>
            </ng-container>
         </div>
         <div class="perf-eval-item-notes">
            <app-text-editor
               label="Notes"
               [editorConfig]="editorConfig"
               [formCtrl]="getControl('notes')"
               [canEdit]="true"
            ></app-text-editor>
         </div>
      </mat-card-content>
   </mat-card>
</form>
